import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import nft from '../images/logo21.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import eth from '../images/eth.png';
import Polygon from '../images/matic.png';
import Avalanche from '../images/avax.png';
import bsc from '../images/bnb.png';
import Arbitrum from '../images/arb.png';
import Optimism from '../images/op.png';
import base from '../images/base.png';

const About2 = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="aboutMain-2">

                <div class="aboutMain2">

                    <div class="about2" data-aos="fade" data-aos-duration="500">
                        <div class="aboutT">On-Demand Liquidity</div>
                        <div class="aboutT2">At the core of "We Will Buy It" is our commitment to providing on-demand liquidity for your NFTs. This means offering a swift and efficient solution to meet your liquidity needs. We support a variety of blockchain networks, including:</div>
                        
                        <p className='aboutLiMain'>

                        <li class="aboutLi">
                            <img src={eth} />
                            <div>Ethereum</div>
                        </li>

                        <li class="aboutLi">
                            <img src={Polygon} />
                            <div>Polygon</div>
                        </li>

                        <li class="aboutLi">
                            <img src={Avalanche} />
                            <div>Avalanche</div>
                        </li>

                        <li class="aboutLi">
                            <img src={bsc} />
                            <div>Binance Smart Chain</div>
                        </li>

                        <li class="aboutLi">
                            <img src={Arbitrum} />
                            <div>Arbitrum</div>
                        </li>

                        <li class="aboutLi">
                            <img src={Optimism} />
                            <div>Optimism</div>
                        </li>

                        <li class="aboutLi">
                            <img src={base} />
                            <div>Base</div>
                        </li>
                        </p>
                        <div class="aboutT2">Our platform ensures that you have access to quick and seamless liquidity for your NFT assets.</div>

                    </div>

                    <img class="deploy2" src={nft} data-aos="zoom-in" data-aos-duration="500" />

                </div>
            </div>

        </>
    );
};

export default About2;