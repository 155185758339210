import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import { contractStaking } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import check from '../images/checkmark.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';


const os = () => {
    window.open("https:/opensea.io");
}

const os2 = () => {
    window.open("https://support.opensea.io/hc/en-us/articles/5095272825235-What-NFTs-are-in-the-hidden-tab-in-my-profile-#:~:text=Viewing%20your%20hidden%20NFTs%20on%20OpenSea&text=You%20can%20easily%20filter%20hidden,to%20unhide%2C%20and%20select%20Unhide.");
}

const NFTComponent = ({ groupedNFTs, approvalStatus, contractStaking }) => {
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [signerAddress, setSignerAddress] = useState('');
    const [_combinedData, setCombinedData] = useState('');
    const [_isApproved, set_isApproved] = useState(false);
    const [approvalItems, setApprovalItems] = useState([]);
    const [isReady, setIsReady] = useState(false);


    const toggleNFTSelection = (nft) => {
        const isSelected = selectedNFTs.some((selectedNFT) => selectedNFT.identifier === nft.identifier);

        if (isSelected) {
            setSelectedNFTs((prevSelectedNFTs) =>
                prevSelectedNFTs.filter((selectedNFT) => selectedNFT.identifier !== nft.identifier)
            );
        } else {
            setSelectedNFTs((prevSelectedNFTs) => [...prevSelectedNFTs, nft]);
        }
    };

    useEffect(() => {

        if (Object.keys(groupedNFTs).length > 0) {
            setIsReady(true);
        }
        console.log("groupedNFTs[0] :", groupedNFTs[Object.keys(groupedNFTs)[0]]);
    }, [groupedNFTs]);


    const setApprovalForAll = async (contractAddress) => {

        try {

            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            let provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);
            const getnetwork = await provider.getNetwork();

            const contractNFT = new ethers.Contract(contractAddress, TwitterAbi.standardABI, signer);

            const transaction = await contractNFT.setApprovalForAll(contractStaking, true, { gasLimit: 385000 });
            // Wait for the transaction to be mined
            await transaction.wait();

            //await fetchMyNFTs();

            await new Promise(resolve => setTimeout(resolve, 2000));
            window.location.reload(true);

        } catch (err) {
            console.log(err);
        }
    }
    const stakeSelectedNFTs = async () => {
        try {

            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            let provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);
            const getnetwork = await provider.getNetwork();

            console.log("signerAddress :" + signerAddress);
            console.log("getnetwork :" + getnetwork.chainId);

            const contract = new ethers.Contract(contractStaking, TwitterAbi.abi, signer);

            if (!selectedNFTs || selectedNFTs.length === 0) {
                // Handle case where no NFTs are selected
                console.warn('No NFTs selected for staking');
                return;
            }



            // Rest of the code remains unchanged
            const tokenIDs = selectedNFTs.map((nft) => nft.identifier);
            const contractAddresses = selectedNFTs.map((nft) => nft.contract);
            console.log('tokenIDs :' + tokenIDs);
            console.log('contractAddresses :' + contractAddresses);
            // Call the stakeNFTs function in the smart contract


            // const owner = await contract.owner();
            //console.log("Owner :" + owner);
            const contractAmount = tokenIDs.length;
            console.log("contractAmount : " + contractAmount);

            const valueInWei = BigNumber("1000000000000000000");
            const gasLimit = 685000;

            const costFromContract = await contract.getLatestPrice();
            // Continue with the rest of your code
            console.log('Cost from contract:', costFromContract._hex);
            const intValue = parseInt(costFromContract._hex, 16);
            console.log("PriceConversion : " + intValue);

            const maticPrice = 10 ** 26 / intValue;
            console.log("maticPrice : " + maticPrice);

            const cost = contractAmount * maticPrice /*ethers.utils.parseUnits((tokenIDs.length * 0.0001).toString(), 18)*/;
            const transaction = await contract.stakeNFTs(tokenIDs, contractAddresses, {
                value: cost.toString()/*ethers.utils.parseEther(cost)*/, gasLimit: 685000
            });

            // Wait for the transaction to be mined
            await transaction.wait();

            await new Promise(resolve => setTimeout(resolve, 2000));
            window.location.reload(true);

            // Update UI or handle success
            console.log('NFTs successfully staked');
        } catch (error) {
            // Handle errors
            console.error('Error staking NFTs:', error.message);
        }
    };


    return (
        <div>

            {isReady ? (
                <div>
                    {Object.values(groupedNFTs).map((group, index) => (
                        <div className='myNFTsMain' key={index}>
                            <div className="conName">{group.collection.replace(/-/g, ' ').toUpperCase()}&nbsp;&nbsp;

                                {!group.isApproved ? (
                                    <button
                                        onClick={() => setApprovalForAll(group.contract)}
                                        className="approveButton"
                                    >
                                        Approve
                                    </button>
                                ) : (
                                    <button className="approvedButton">Approved</button>
                                )}


                            </div>
                            <p className="conAdd">
                                Contract: <a href={`https://polygonscan.com/address/${group.contract}`} className="address" target="_blank" rel="noopener noreferrer">{group.contract}</a>
                            </p>

                            {group.isApproved ? (

                                <div className="myNFTs">
                                    {group.nfts.map((nft) => (
                                        <div className='myNFTs' key={nft.identifier}>
                                            <div className='myNFTs2'>
                                                <img
                                                    className={`nfts ${selectedNFTs.some(
                                                        (selectedNFT) => selectedNFT.identifier === nft.identifier
                                                    ) && 'selected'}`}
                                                    src={nft.image_url}
                                                    alt={nft.name}
                                                    onClick={() => toggleNFTSelection(nft)}
                                                />
                                                {selectedNFTs.some((selectedNFT) => selectedNFT.identifier === nft.identifier) && (
                                                    <img className='tic' src={check} />
                                                )}
                                                <div className='id'>{nft.name} (ID: {nft.identifier})</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>) : null}
                        </div>
                    ))}


                    <div className='myNFTsMain'>
                        <div className="conName">Selected NFTs</div>
                        <div className='myNFTs'>
                            {selectedNFTs.map((nft) => (
                                <div className='myNFTs' key={nft.identifier}>
                                    <div className='myNFTs2'>
                                        <img className='nfts' src={nft.image_url}
                                            alt={nft.name} />
                                        <div className='id'>{nft.name} (ID: {nft.identifier})</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {selectedNFTs.length > 0 ?
                            (<button onClick={stakeSelectedNFTs} className='connectBtn3'>
                                Send Selected NFTs
                            </button>) :
                            (<button disabled className='connectBtn3Dis'>
                                Send Selected NFTs
                            </button>)}
                    </div>
                </div>) : null}
        </div>
    );
};

export default NFTComponent;