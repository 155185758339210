import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div class="faqbg">
      <div id="faq">

          <div class="header2" data-aos="fade" data-aos-duration="500">Frequently Asked <br/> Questions</div>

          <div class="accordin reveal">

          <details >
            <summary>Which blockchain networks do you support?</summary>
            <div class="faq__content">
              <p>"We Will Buy It" supports various blockchain networks, including Ethereum, Polygon, Avalanche, Binance Smart Chain, Arbitrum, Optimism, and Base. Our platform is designed to cater to a diverse range of users across these networks, ensuring flexibility and accessibility for NFT sellers.</p>
            </div>
          </details>
          <details>
            <summary>How many NFTs am I allowed to sell in a single transaction?</summary>
            <div class="faq__content">
              <p>You can sell up to 500 NFTs in one transaction, providing a flexible and efficient option for managing your NFT portfolio. If your needs exceed this limit, initiating a second transaction is a straightforward solution.</p>

            </div>
          </details>
          <details>
            <summary>Is selling my NFT to "We Will Buy It" eligible for a Tax Write-Off?</summary>
            <div class="faq__content">
              <p>Selling your NFT to "We Will Buy It" for more than $0.01 is highly likely to qualify for a tax write-off, potentially lowering your taxes for the current year or allowing you to carry it forward for future tax obligations. The impact on your tax bill depends on factors such as income, capital gains, and other expenses. For personalized advice, consult with a tax professional.</p>

            </div>
          </details>

          <details>
            <summary>Can I repurchase my NFT after selling it?</summary>
            <div class="faq__content">
              <p>While we cannot guarantee the option to repurchase your NFT after selling it, this approach ensures that the sale remains a genuine "arm's length transaction," avoiding potential conflicts of interest tied to future economic benefits. However, you have the flexibility to bid on any NFT listed on OpenSea at any time, whether it's one you've previously sold or a new acquisition.</p>

            </div>
          </details>

          <details>
            <summary>What are the usage costs?</summary>
            <div class="faq__content">
              <p>Each transaction incurs a service fee of $1 per NFT, in addition to gas fees. For those with a substantial number of NFTs, our service fee cap of $40 ensures a maximum charge in a single transaction, regardless of the quantity of NFTs sold. The smart contract is designed to optimize for the lowest platform and gas fees, enabling the processing of up to 500 NFTs in a single transaction while maintaining cost-effectiveness.</p>
            </div>
          </details>

        </div>
      </div>
      </div>


    )
  }
}

export default FAQ;

