import React, { useState, useEffect } from 'react';
import '../src/components/payment.css';
import { contractStaking } from '../src/config';
import TwitterAbi from '../src/abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../src/images/logo.png';
import { Route, Routes } from 'react-router-dom';
import Arb from './pages/arb';
import Avax from './pages/avax';
import Base from './pages/base';
import Bnb from './pages/bnb';
import Main from './pages/main';
import Home from './pages/home';
import Op from './pages/op';
import Matic from './pages/polygon';
import { Link } from 'react-router-dom';

const App = () => {

  return (
    <>

      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/Eth' element={<Main />} />
        <Route path='/Arb' element={<Arb />} />
        <Route path='/Avax' element={<Avax />} />
        <Route path='/Base' element={<Base />} />
        <Route path='/Bnb' element={<Bnb />} />
        <Route path='/Main' element={<Main />} />
        <Route path='/Op' element={<Op />} />
        <Route path='/Matic' element={<Matic />} />

      </Routes>

    </>
  );
};

export default App;
