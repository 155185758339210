import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../images/logo.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import wallet from '../images/wallet.png';
import nft from '../images/nft.png';
import sell from '../images/selling.png';
import bnb from '../images/bnb.svg';
import fantom from '../images/fantom.svg';
import arbitrum from '../images/arbitrum.svg';
import optimism from '../images/optimism.svg';
import base from '../images/base.svg';
import polyzk from '../images/polygon-zkevm.svg';
import Aos from 'aos';

const Process = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="processMain" data-aos="zoom-in" data-aos-duration="500">

                <div class="header">How it works</div>

                <div class="worksMain" data-aos="fade" data-aos-duration="500">
                    <div class="worksMain2"><img src={wallet} /><div class="worksMain2Div">Link your digital wallet</div></div>
                    <div class="worksMain2"><img src={nft} /><div class="worksMain2Div">Choose the NFT you'd like to offer for sale</div></div>
                    <div class="worksMain2"><img src={sell} /><div class="worksMain2Div">Trade your NFT and receive 0.00000001 ETH in exchange</div></div>
                </div>
            </div>

        </>
    );
};

export default Process;
